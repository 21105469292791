import { Component, OnInit } from '@angular/core';
import { BoutiqueDataService } from '../../../../../shared/services/utils/data/boutique.service';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../../../../seo.service';
import { Router } from '@angular/router';
import { DataService } from '../../../../../shared/services/utils/data/data.service';

@Component({
  selector: 'app-boutique',
  templateUrl: './boutique.component.html',
  styleUrls: ['./boutique.component.scss']
})
export class BoutiqueComponent implements OnInit {
  data: any;

  constructor(
    private readonly boutiqueData: BoutiqueDataService,
    private readonly dataService: DataService,
    private readonly title: Title,
    private readonly seoService: SeoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.data = this.boutiqueData.getBoutiqueData();
    this.seoService.setCanonicalURL();
    this.seoService.updateMetaInformationPage(this.boutiqueData.getBoutiqueData());
    this.titleConfig();
  }
  titleConfig = () => {
    const titlePage = `${this.data.seo.seoTitle}`;
    const appName = this.dataService.getCommonData().common.companyName;
    this.title.setTitle(`${appName} ${titlePage}`);
  }

  goButtons = (): void => {

    this.router.navigateByUrl(this.boutiqueData.getBoutiqueData().showcase.content.buttons[0].redirect);

  }
}
