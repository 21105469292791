import { Component, OnInit, Inject } from '@angular/core';

import { DataService } from '../../services/utils/data/data.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  today: Date = new Date();
  buttonUrlData: string;
  data: any;
  wapUrl: string;

  constructor(
    @Inject('DataService') private readonly serviceData: DataService,
  ) { }

  ngOnInit(): void {
    this.data = this.serviceData.getCommonData();

  }
  buttonUrlExternal = () => {
    this.buttonUrlData = this.data.common.appDeveloperUrl;
    this.wapUrl = `${this.buttonUrlData}?fromClient=osvi&campaign=osvi-client`;
    window.open(this.wapUrl, '_blank');
  }

}
