import { Component, Input, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';

import { DataService } from '../../../../shared/services/utils/data/data.service';
import { NavbarDataService } from '../../../../shared/services/utils/data/navbar.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  isShowDivIf = true;
  navbarData: any;

  @Input() commonData: any;
  @Input() navbarProperty: string;
  @ViewChild('sidenav') sidenav: MatSidenav;

  opened: boolean;

  /**
   * Is collapsed
   */
  public isCollapsed = true;


  constructor(
    public location: Location,
    private readonly dataService: DataService,
    private readonly navDataService: NavbarDataService,
    private readonly elementRef: ElementRef,
  ) {
  }

  ngOnInit() {
    this.navbarData = this.navDataService.getNavbarData();
    this.commonData = this.dataService.getCommonData();
  }

  clickHandler = () => {
    this.sidenav.close();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const navbarElement = this.elementRef.nativeElement.querySelector('.navbar-fixed');
    if (window.pageYOffset > navbarElement.offsetTop) {
      navbarElement.classList.add('navbar-fixed-top');
    } else {
      navbarElement.classList.remove('navbar-fixed-top');
    }
  }

}
