import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { DataService } from '../../../../shared/services/utils/data/data.service';
import { SeoService } from '../../../../seo.service';
import { Router } from '@angular/router';
import { AboutDataService } from '../../../../shared/services/utils/data/about.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  /**
   * Set about data
   */
  data: any;

  constructor(
    private readonly dataService: DataService,
    private readonly aboutService: AboutDataService,
    private readonly title: Title,
    private seoService: SeoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.data = this.aboutService.getAboutUsData();
    this.seoService.setCanonicalURL();
    this.seoService.updateMetaInformationPage(this.aboutService.getAboutUsData());
    this.titleConfig();
  }

  titleConfig = () => {
    const titlePage = this.data.showcase.title;
    const appName = this.dataService.getCommonData().common.companyName;
    this.title.setTitle(`${appName} ${titlePage}`);
  }

  goButtons = (event: any): void => {
    if (event.index === 0) {
      this.router.navigateByUrl(this.aboutService.getAboutUsData().showcase.content.buttons[0].redirect);
    } else {
      this.router.navigateByUrl(this.aboutService.getAboutUsData().showcase.content.buttons[1].redirect);
    }
  }

}
