import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { DataService } from '../../../../shared/services/utils/data/data.service';
import { SeoService } from '../../../../seo.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  /**
   * Set data
   */
  data: any;
  city: string;


  constructor(
    private readonly dataService: DataService,
    private readonly title: Title,
    private readonly seoService: SeoService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.data = this.dataService.getHomeData();
    this.seoService.setCanonicalURL();
    this.seoService.updateMetaInformationPage(this.dataService.getHomeData());
    this.titleConfig();
  }

  titleConfig = () => {
    const titlePage = `${this.data.seo.seoTitle}`;
    const appName = this.dataService.getCommonData().common.companyName;
    this.title.setTitle(`${appName} ${titlePage} ${this.city}`);
  }

  goButtons = (event: any): void => {
    if (event.index === 0) {
      this.router.navigateByUrl(this.dataService.getHomeData().showcase.content.buttons[0].redirect);
    } else {
      window.open(this.dataService.getHomeData().showcase.content.buttons[1].redirect);
    }

  }

  goSinceSlider = (url: string) => {
    this.router.navigateByUrl(url);
  }
}
