import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class EsteticaDataService {

  constructor() { }

  /**
   * Set data on services page
   */
  getEsteticaData = () => {
    return {
      seo: {
        seoTitle: 'Estética y spa para mascotas',
        seoExcerpt: 'servicio de estética y spa para mascotas en Toluca',
        seoKeywords: 'Estética canina, Spa para mascotas, Peluquería canina, Corte de pelo para perros, Baño y secado de mascotas',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'items-center grid lg:grid-cols-2',
        showcaseOrder: '',
        imageClasses: 'rounded-full',
        useImage: {
          show: true,
          imageUrl: 'assets/img/projects/dog_spa_2.png',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          lights: false,
          classes: 'bg-zinc-900 bg-cover bg-center bg-no-repeat ',
        },
        content: {
          shortLine: 'Consentiremos a tu mascota',
          title: `Estética & Spa`,
          excerpt: `En OSVI, consideramos la salud de las mascotas de manera integral. Sabemos que mantener a tu compañero canino o felino aseado y saludable es fundamental para su bienestar. Por eso, ofrecemos un servicio de estética y spa para mascotas en Toluca. 
         ¡Agenda una cita con nosotros y sorpréndete con los resultados!`,
          classesTitle: `text-white text-center text-6xl animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          buttons: [
            {
              id: 0,
              title: 'Agendar una cita',
              ui: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
              redirect: '/contacto',
              icon: 'fa fa-calendar-check-o',
            },
          ],
          switchSkinTextColor: 'text-zinc-200',
        },
        textAlign: 'text-center',
        showSeparator: null,
      },
      overview:
      {
        classesColumn: 'pb-6',
        title: 'Consiente a tu mascota con nuestro servicio de estética y spa en Toluca',
        classesTitle: `text-3xl`,
        description: `En OSVI, no solo consentiremos a tu mascota, también proyectaremos la salud y 
        el bienestar que disfruta.  En nuestro spa, no solo podrás consentir a tu peludo amigo, sino también garantizar su buena apariencia y excelente calidad de vida, en línea con tu estilo de vida exigente.
        <ul class="hide-list-icon">
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3" aria-hidden="true"></i> Corte de pelo</li>
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3" aria-hidden="true"></i> Cepillado</li>
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3" aria-hidden="true"></i> Corte de uñas</li>
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3 " aria-hidden="true"></i> Limpieza dental</li>
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3 " aria-hidden="true"></i> Limpieza glándulas anales</li>
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3 " aria-hidden="true"></i> Limpieza de oído</li>
        </ul>`,
        // imageOther: 'assets/img/default/bg-default.jpg',
        switchBackground: 'bg-zinc-900 text-zinc-100',
        lottie: null,
        imageOther: 'assets/img/projects/cat_spa.png',
        imageOtherClasses: 'rounded-full',

      },
      callToAction:
      {
        switchBackground: 'bg-white text-zinc-900',
        orderColumn1: 'order-1',
        useBackground: 'assets/img/projects/hedgehog_yellow.png',
        useBackgroundclasses: 'bg-cover bg-repeat-y bg-right lg:bg-left flex',
        title: '¿Estás buscando la mejor atención veterinaria para tus mascotas en Toluca?',
        classesTitle: `text-3xl lg:text-5xl text-white lg:text-zinc-900`,
        description: `<p class="text-white lg:text-zinc-900">Nuestro equipo de veterinarios altamente capacitados está dedicado a proporcionar la mejor atención médica para tus animales de compañía, desde perros y gatos hasta aves y reptiles. Contamos con una amplia gama de servicios que incluyen consultas médicas, cirugía, exámenes de diagnóstico, tratamiento de enfermedades, vacunaciones y más.</p>`,
        button: 'Agendar cita',
        buttonExternal: false,
        buttonUrl: '/contacto',
        buttonIcon: 'fa fa-long-arrow-right '
      }
    }
  }
}


