import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html'
})
export class TableListComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Output() eventClick: EventEmitter<void> = new EventEmitter;

  constructor() { }

  ngOnInit() {
  }

  goAddPage = () => this.eventClick.emit();

}
