import { Component, Input, EventEmitter, Output } from '@angular/core';


import { ContentButtonsModel, UseBackgroundModel, ShowcaseModel } from '../../../models/showcase.model';


@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
})
export class ShowcaseComponent {
  /**
 * Set short description after the title
 */
  @Input() showcaseData: ShowcaseModel;

  @Input() cityName: string;

  @Input() containerClasses: string;
  /**
   * Set description
   */
  @Input() showDescription: boolean;
  /**
   * Show separator
   */
  @Input() showSeparator: boolean;
  /**
   * Show image
   */
  @Input() showImage: boolean;
  /**
   * Show image
   */
  @Input() showVideo: boolean;
  /**
   * Show image
   */
  @Input() showForm: boolean;

  /**
   * Change background color
   */
  @Input() switchSkinBackground: string;
  /**
   * Change color for the text
   */
  @Input() switchSkinTextColor: string;
  /**
     * Show backgroundImage
     */
  @Input() backgroundImage: boolean;
  /**
   * Set background image properties
   */
  @Input() backgroundImageProperty: string;
  /**
    * Set column to left = true or right = false
     */
  @Input() naturalPositionColumn: boolean;
  /**
     * Show column at center
     */
  @Input() centerColumn: boolean;
  /**
    * Set text align
    */
  @Input() textAlign: string;
  /**
   * Set an event on click
   */
  @Output() eventClick: EventEmitter<any> = new EventEmitter();

  useBackground: UseBackgroundModel;

  buttons: ContentButtonsModel[];

  constructor(
  ) {
    this.useBackground = {
      urlVideo: '',
      urlImage: '',
      classes: '',
    }
  }

  handleClick = (index: number) => {
    this.eventClick.emit({ index });
  }

}
