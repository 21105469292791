import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../../shared/services/utils/data/data.service';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../../../../seo.service';
import { Router } from '@angular/router';
import { EsteticaDataService } from '../../../../../shared/services/utils/data/estetica.service';

@Component({
  selector: 'app-estetica',
  templateUrl: './estetica.component.html',
  styleUrls: ['./estetica.component.scss']
})
export class EsteticaComponent implements OnInit {
  data: any;
  constructor(
    private readonly esteticaData: EsteticaDataService,
    private readonly dataService: DataService,
    private readonly title: Title,
    private readonly seoService: SeoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.data = this.esteticaData.getEsteticaData();
    this.seoService.setCanonicalURL();
    this.seoService.updateMetaInformationPage(this.esteticaData.getEsteticaData());
    this.titleConfig();
  }
  titleConfig = () => {
    const titlePage = `${this.data.seo.seoTitle}`;
    const appName = this.dataService.getCommonData().common.companyName;
    this.title.setTitle(`${appName} ${titlePage}`);
  }
  goButtons = (): void => {

    this.router.navigateByUrl(this.esteticaData.getEsteticaData().showcase.content.buttons[0].redirect);

  }
}
