import { Component } from '@angular/core';
import { LoadingService } from '../../shared/services/utils/loader/loader.service';
import { DataService } from '../../shared/services/utils/data/data.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  data: any;
  loading$ = this.loader.loading$;
  constructor(
    public loader: LoadingService,
    private readonly dataService: DataService
  ) {
    this.data = this.dataService.getCommonData();
  }
}
