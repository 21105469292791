import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';


@Component({
  selector: 'app-return',
  template: `
    <div class="fixed z-10 text-red-400  bg-zinc-900 p-2 w-full bottom-0 left-0 text-right">
  <div class="flex flex-row justify-between items-center px-4">
    <h4 class="font-montserrat uppercase m-0 p-0 text-sm">{{title}}</h4> <button class="button text-xs" (click)="handleClick()"> <em class="fa fa-chevron-left"></em> Regresar a OnePage</button>
  </div>
</div>
  `,
})
export class ReturnComponent implements OnInit {

  /**
   * Set an event on click
   */
  @Output() goToUrl: EventEmitter<string> = new EventEmitter;

  /**
   * Define a title
   */
  @Input() title = "Título";

  constructor() { }

  ngOnInit(): void {

  }

  handleClick = () => {
    this.goToUrl.emit();
  }

}
