import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../../shared/services/utils/data/data.service';
import { Router } from '@angular/router';
import { SeoService } from '../../../../../seo.service';
import { Title } from '@angular/platform-browser';
import { PensionDataService } from '../../../../../shared/services/utils/data/pension.service';

@Component({
  selector: 'app-pension',
  templateUrl: './pension.component.html',
  styleUrls: ['./pension.component.scss']
})
export class PensionComponent implements OnInit {
  data: any;
  constructor(
    private readonly pensionData: PensionDataService,
    private readonly dataService: DataService,
    private readonly title: Title,
    private readonly seoService: SeoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.data = this.pensionData.getPensionData();
    this.seoService.setCanonicalURL();
    this.seoService.updateMetaInformationPage(this.pensionData.getPensionData());
    this.titleConfig();
  }
  titleConfig = () => {
    const titlePage = `${this.data.seo.seoTitle}`;
    const appName = this.dataService.getCommonData().common.companyName;
    this.title.setTitle(`${appName} ${titlePage}`);
  }
  goButtons = (): void => {

    this.router.navigateByUrl(this.pensionData.getPensionData().showcase.content.buttons[0].redirect);

  }
}
