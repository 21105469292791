import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class MedicinaService {
  /**
   * Set data on offers page
   */
  getMedicinaData = () => {
    return {
      seo: {
        seoTitle: 'Medicina Veterinaria Toluca',
        seoExcerpt: 'Nos dedicamos a proporcionar servicios integrales para el cuidado de la salud de sus mascotas.',
        seoKeywords: 'veterinaria Toluca, atención veterinaria, emergencias veterinarias, especialistas en animales,  cuidado de mascotas.',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'items-center grid lg:grid-cols-2',
        showcaseOrder: '',
        imageClasses: 'rounded-full flip-image',
        useImage: {
          show: true,
          imageUrl: 'assets/img/projects/cat_pic.png',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          lights: false,
          classes: 'bg-amber-600 bg-cover bg-center bg-no-repeat ',
        },
        content: {
          shortLine: 'Comprometidos con el bienestar de su mascota',
          title: `Medicina`,
          excerpt: `Nos dedicamos a proporcionar servicios integrales para el cuidado de la salud de sus mascotas. Nuestro equipo de profesionales altamente capacitados se enfoca en ofrecer una atención personalizada para garantizar que sus animales de compañía reciban el mejor tratamiento posible.`,
          classesTitle: `text-white text-center text-6xl animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          buttons: [
            {
              id: 0,
              title: 'Agendar una cita',
              ui: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
              redirect: '/contacto',
              icon: 'fa fa-calendar-check-o',
            },
          ],
          switchSkinTextColor: 'text-zinc-200 px-6',
        },
        textAlign: 'text-center',
        showSeparator: null,
      },
      overview:
      {
        classesColumn: 'pb-6 pt-6',
        title: 'Tu aliado en la salud de tu mascota. ',
        classesTitle: `text-3xl`,
        description: `Nuestros servicios incluyen medicina general, vacunación y desparasitación. Recomendamos programas de vacunación anuales para asegurarnos de que su mascota esté protegida contra enfermedades comunes. También ofrecemos planes de desparasitación para ayudar a prevenir enfermedades parasitarias que pueden ser perjudiciales para la salud de su mascota.
        <ul class="hide-list-icon">
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3" aria-hidden="true"></i> Desparasitación</li>
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3" aria-hidden="true"></i> Vacunación</li>
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3" aria-hidden="true"></i> Consulta</li>
        <li class="lg:text-2xl"><i class="fa fa-paw mr-3 " aria-hidden="true"></i> Cirugía</li>
        </ul>`,
        // imageOther: 'assets/img/default/bg-default.jpg',
        switchBackground: 'bg-white text-zinc-900',
        lottie: null,
        imageOther: 'assets/img/projects/vet.png',
        imageOtherClasses: 'rounded-full',

      },
      feature: {
        switchBackground: 'bg-amber-600 text-zinc-900',
        title: 'Servicios médicos en OSVI',
        classesTitle: `text-3xl text-center text-white`,
        classesDescription: 'text-center text-zinc-200',
        description: `OSVI ofrece servicios veterinarios completos para mascotas: medicina, vacunación, desparasitación, consultas y cirugía. 
        <br>Nuestro equipo de profesionales altamente capacitados está aquí para garantizar la salud y el bienestar de tu mejor amigo.`,
        items: [
          {
            cardProperty: 'text-center bg-[#E9E6E3] rounded-md',
            iconImage: 'assets/img/icons/2.png',
            iconTagline: 'Desparasitación',
            iconDesc: `En Osvi estamos preocupados por la salud de tu mascota, por lo tanto si nos visitas nuestros especialistas te brindarán la información necesaria respecto al cuadro de desparasitación óptimo para tu mascota.`,
          },
          {
            cardProperty: 'text-center bg-[#E9E6E3]  rounded-md',
            iconImage: 'assets/img/icons/1.png',
            iconTagline: 'Vacunación',
            iconDesc: `En OSVI la salud de tu mascota es lo más importante, por ello hemos diseñado un pre-diagnóstico de adaptación al hogar de por lo menos ocho días, antes de poder aplicar la vacunación a tu mascota, esto con el fin de monitorear la actividad sensorial y el desenvolvimiento de la misma, en convivencia con su entorno.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },
          {
            cardProperty: 'text-center bg-[#E9E6E3]  rounded-md',
            iconImage: 'assets/img/icons/3.png',
            iconTagline: 'Consulta',
            iconDesc: `En OSVI el servicio de consulta médica es una forma de diagnosticar que pasa con tu mascota, en ella se integra la asesoría necesaria especializada y de manera personalizada de nuestros profesionales médicos, por lo tanto no solo te damos a conocer las herramientas con las cuales podremos enfrentar el malestar que aqueja a tu mascota, sino que también podremos prevenir circunstancias de mayor problemática, en OSVI la salud es integral.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },
          {
            cardProperty: 'text-center bg-[#E9E6E3]  rounded-md',
            iconImage: 'assets/img/icons/4.png',
            iconTagline: 'Cirugía',
            iconDesc: `En OSVI sabemos que los accidentes o los diagnósticos que conllevan a una cirugía ocurren de forma cotidiana, por ello te ofrecemos el equipo quirúrgico más profesional y con la mayor calidad en medicina Veterinaria, sabemos que no es fácil enfrentar retos como estos, por ello nuestros especialistas y profesionales ponen a tu disposición su capacidad para tu bienestar y el de tu mascota.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },

        ]
      },
      callToAction:
      {
        switchBackground: 'bg-white text-zinc-900',
        image: 'assets/img/projects/vet_online.png',
        title: 'Dr. OSVI Online',
        classesTitle: `lg:text-4xl text-3xl mb-4`,
        description: `<p>La Asesoría Veterinaria Médica Online es un servicio en línea que proporciona atención y consejos médicos a distancia a dueños de mascotas. Los veterinarios brindan información y orientación para el cuidado de la salud de las mascotas a través de consultas virtuales, chats, correos electrónicos y videollamadas.</p>`,
        button: 'Solicitar asesoría médica ahora',
        buttonExternal: true,
        buttonUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=Hola,%20¿Quiero%20agendar%20con%20el%20Dr.OSVI%20Online',
        buttonIcon: 'fa fa-long-arrow-right '
      },
    }
  }
}