import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '../sub-modules/home/home.component';
import { ContactComponent } from '../sub-modules/contact/contact.component';
import { AboutUsComponent } from '../sub-modules/about-us/about-us.component';
import { PrivacyComponent } from '../sub-modules/privacy/privacy.component';
import { MedicinaComponent } from '../sub-modules/services/medicina/medicina.component';
import { BoutiqueComponent } from '../sub-modules/services/boutique/boutique.component';
import { EsteticaComponent } from '../sub-modules/services/estetica/estetica.component';
import { PensionComponent } from '../sub-modules/services/pension/pension.component';
import { NutricionComponent } from '../sub-modules/services/nutricion/nutricion.component';
import { ConvenioComponent } from '../sub-modules/convenio/convenio.component';




export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: '', component: HomeComponent },
  { path: 'nosotros', component: AboutUsComponent },
  { path: 'medicina', component: MedicinaComponent },
  { path: 'convenio-uaemex', component: ConvenioComponent },
  { path: 'boutique', component: BoutiqueComponent },
  { path: 'estetica-y-spa', component: EsteticaComponent },
  { path: 'pension', component: PensionComponent },
  { path: 'centro-nutricional', component: NutricionComponent },
  { path: 'contacto', component: ContactComponent },
  { path: 'aviso-privacidad', component: PrivacyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
