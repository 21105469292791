import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PricesModel } from '../../../models/prices.model';

@Component({
  selector: 'app-call-price',
  templateUrl: './call-price.component.html',
})
export class CallPriceComponent implements OnInit {

  @Input() pricesData: PricesModel;
  @Output() eventClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleClick = (index: any) => {
    this.eventClick.emit({ index });
  }

}
