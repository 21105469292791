import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class NutricionDataService {

  constructor() { }

  /**
   * Set data on services page
   */
  getNutricionData = () => {
    return {
      seo: {
        seoTitle: 'Centro Nutricional Veterinaria',
        seoExcerpt: 'Ofrecemos una asesoría completa y personalizada en el manejo y elección del alimento adecuado para cada una de nuestras mascotas.',
        seoKeywords: 'Peso ideal, centro nutricional, saludable, alimento premium mascotas',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'items-center grid lg:grid-cols-2',
        showcaseOrder: '',
        imageClasses: 'rounded-full',
        useImage: {
          show: true,
          imageUrl: 'assets/img/projects/dalmata_magenta.png',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          lights: false,
          classes: 'bg-fuchsia-900 bg-cover bg-center bg-no-repeat ',
        },
        content: {
          shortLine: 'Consentiremos a tu mascota',
          title: `Centro Nutricional`,
          excerpt: `En OSVI entendemos que la nutrición es una parte fundamental de la salud de tu mascota. Por esta razón, ofrecemos una asesoría completa y personalizada en el manejo y elección del alimento adecuado para cada una de nuestras mascotas.`,
          classesTitle: `text-white text-center text-6xl animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          buttons: [
            {
              id: 0,
              title: 'Agendar una cita',
              ui: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
              redirect: '/contacto',
              icon: 'fa fa-calendar-check-o',
            },
          ],
          switchSkinTextColor: 'text-zinc-200',
        },
        textAlign: 'text-center',
        showSeparator: null,
      },
      overview:
      {
        classesColumn: 'pb-6 pt-6',
        title: 'Alcanza el peso ideal de tu mascota de forma saludable',
        classesTitle: `text-3xl`,
        description: `Nuestros expertos en nutrición animal toman en cuenta diversos factores, como la raza, el peso, tamaño y especie, para garantizar que tu mascota reciba una alimentación de alta calidad y que además sea nutritiva y fácilmente digerible.
        <br>Esto se traduce en heces firmes y saludables, lo que te brindará la seguridad de que la alimentación de tu mascota se desarrolla de manera íntegra y confiable.

        <br>Estamos comprometidos con el bienestar de tu mascota y trabajamos para asegurar que reciba una alimentación balanceada y adecuada para su desarrollo y salud. Visítanos y descubre cómo podemos ayudarte a mantener a tu mascota saludable y feliz.`,
        // imageOther: 'assets/img/default/bg-default.jpg',
        switchBackground: 'bg-white text-zinc-900',
        lottie: null,
        imageOther: 'assets/img/projects/erizo.png',
        imageOtherClasses: 'rounded-full',

      },
      brands: {
        switchBackground: 'bg-white text-zinc-900',
        introTitle: `Descubre los alimentos más saludables <br>para tu mascota.<br> 🐶🐱🐟🐰🐢`,
        classesTitle: 'text-4xl',
        brands: [
          {
            imageUrl: 'assets/img/brand/excellent.png',
            businessCategory: 'nutrición canina y felina'
          },
          {
            imageUrl: 'assets/img/brand/hills.png',
            businessCategory: 'nutrición canina y felina'
          },
          {
            imageUrl: 'assets/img/brand/mazuri.png',
            businessCategory: 'nutrición animales exóticos'
          },
          {
            imageUrl: 'assets/img/brand/tetra.png',
            businessCategory: 'nutrición para acuáticos'
          },
          {
            imageUrl: 'assets/img/brand/wardley.png',
            businessCategory: 'nutrición para acuáticos'
          },

        ]
      },
      callToAction:
      {
        switchBackground: 'bg-white text-zinc-900',
        orderColumn1: 'order-2',
        useBackground: 'assets/img/projects/pug_golden.jpg',
        useBackgroundclasses: 'bg-cover bg-repeat-y bg-right lg:bg-left flex',
        title: 'Descuentos para la comunidad UAEMEX',
        classesTitle: `text-3xl lg:text-5xl text-white lg:text-zinc-900`,
        description: `<p class="text-white lg:text-zinc-900">La Veterinaria OSVI se enorgullece en anunciar su convenio de descuentos exclusivo para la comunidad UAMEX.
          Ahora, los estudiantes, profesores y personal administrativo de la UAMEX podrán disfrutar de descuentos especiales en productos y servicios de la clínica veterinaria líder en Toluca. 
          Desde servicios médicos hasta alimentación y accesorios para mascotas, OSVI de Toluca es la opción perfecta para el cuidado de su mascota.</p>`,
        button: 'Ver descuentos',
        buttonExternal: false,
        buttonUrl: '/convenio-uaemex',
        buttonIcon: 'fa fa-long-arrow-right '
      }
    }
  }
}


