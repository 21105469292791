import { Component, Input } from '@angular/core';

import { BrandsListModel } from '../../models/brands-list.model';

@Component({
  selector: 'app-brands-list',
  templateUrl: './brands-list.component.html',
  styleUrls: ['./brands-list.component.scss']
})
export class BrandsListComponent {
  /**
  * Set the url of images to
  */
  @Input() brandsListData: BrandsListModel;

  constructor(
  ) { }
}
