import { Component, Input } from '@angular/core';
import { IntroductionModel } from '../../../models/introduction.model';


@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
})
export class IntroductionComponent {

  @Input() introductionData: IntroductionModel;
  @Input() title: string;
  @Input() description: string;

  constructor(
  ) {

  }


}
