import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class NavbarDataService {

  titleSize: string;
  subtitleSize: string;

  constructor() {
    this.titleSize = 'lg:text-6xl text-4xl mb-4';
    this.subtitleSize = 'lg:text-4xl text-3xl mb-4'
  }

  /**
   * Set data to general aspects of the company
   */
  getNavbarData = () => {
    return {
      common: {
        classesNavbar: 'justify-start',
      },
    }
  }
}