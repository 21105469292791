import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ContactDataService {

  titleSize: string;
  subtitleSize: string;

  constructor() {
    this.titleSize = 'lg:text-6xl text-4xl mb-4';
    this.subtitleSize = 'lg:text-4xl text-3xl mb-4'
  }

  /**
   * Set data on contact page
   */
  getContactData = () => {
    return {
      seo: {
        seoTitle: 'Agenda una cita en OSVI hoy mismo.',
        seoExcerpt: '¡Cuida la salud de tu mascota con la mejor atención veterinaria en Toluca! ',
        seoKeywords: 'Clínica veterinaria OSVI, Veterinaria Toluca,Cita veterinaria,Salud mascotas, Atención médica animal',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'items-center grid lg:grid-cols-2',
        showcaseOrder: '',
        imageClasses: 'rounded-full',
        useImage: {
          show: true,
          imageUrl: 'assets/img/projects/vet.png',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          lights: false,
          classes: 'bg-sky-700 bg-cover bg-center bg-no-repeat ',
        },
        content: {
          shortLine: '¡Cuida la salud de tu mascota!',
          title: `Agenda una cita en OSVI hoy mismo`,
          excerpt: `Agenda una cita para tu mascota en la Clínica Veterinaria OSVI en Toluca y disfruta de una atención de calidad Premium. 
          Nuestro equipo de expertos en salud animal está listo para brindar a tu mascota la atención médica que necesita. Desde consultas médicas, 
          exámenes de diagnóstico, cirugía, hasta servicios de nutrición, vacunación y más, en OSVI nos preocupamos por la salud y bienestar de tu mascota. 
          Agenda tu cita hoy mismo y descubre cómo podemos ayudarte a mantener a tu mascota saludable y feliz. ¡Contáctanos ahora!`,
          classesTitle: `text-white text-center text-6xl animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          buttons: [
            {
              id: 1,
              title: 'Agenda vía Whatsapp',
              redirect: 'https://api.whatsapp.com/send?phone=+527222819532&text=Hola,%20¿Me%20ayudan%20con%20una%20cita%20para%20mi%20mascota?',
              ui: ' font-semibold text-zinc-900 bg-green-300 animate__animated animate__zoomIn',
              icon: 'fa fa-whatsapp',
            },
            {
              id: 1,
              title: 'Llamar al 7222819532',
              ui: 'border-2 border-white font-semibold text-white shadow-sm  animate__animated animate__zoomIn',
              redirect: 'tel:7222819532',
              icon: 'fa fa-phone',
            }
          ],
          switchSkinTextColor: 'text-zinc-200 px-4',
        },
        textAlign: 'text-center',
        showSeparator: null,
      },
    }
  }




}


