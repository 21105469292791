import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SliderModel } from '@app/shared/models/slideshow.model';


@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {
  /**
   * Set an event on click
   */
  @Output() eventClick: EventEmitter<any> = new EventEmitter();
  @Input() class: string;

  @Input() sliderData: SliderModel;


  constructor() {
  }

  ngOnInit(): void {

  }
  goTo = (url: string) => {
    this.eventClick.emit(url);
  }
}