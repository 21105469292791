import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  dataCity: string;
  titleSize: string;
  subtitleSize: string;

  constructor(
  ) {
    this.dataCity = `Toluca`;
    this.titleSize = 'lg:text-6xl text-4xl mb-4';
    this.subtitleSize = 'lg:text-4xl text-3xl mb-4'
  }

  /**
   * Set data to general aspects of the company
   */
  getCommonData = () => {
    return {
      common: {
        theme: 'rounded-theme',
        quote: 'OSVI Clínica Veterinaria <br> <strong class="text-xl">Nuestro reflejo, Tu mascota</strong>',
        excerpt: `Ofrecemos servicios veterinarios de alta calidad con 
        tecnología avanzada y un equipo de especialistas apasionados por la salud y el bienestar animal. Desde consultas 
        generales hasta cirugías especializadas. 
        ¡Ven y descubre cómo podemos ayudarte a mantener a tu mejor amigo feliz y saludable!`,
        companyName: 'OSVI Clínica Veterinaria Toluca',
        companyAddress: 'Toluca, Estado de México, México',
        companyPhone: '7224417125',
        companyLogo: 'assets/img/brand/osvi.svg',
        appDeveloperLogo: 'assets/img/brand/wap-mr-full.svg',
        appDeveloper: 'WAP Agencia Digital',
        appDeveloperUrl: 'https://wap.com.mx/',
        social: [{
          url: 'https://facebook.com/OSVIHospitalVeterinario',
          icon: 'fa fa-facebook',
        },
        ],
        navbarProperty: 'navbar-transparent navbar-dark headroom--pinned',
      },
      footer: {
        switchBackground: 'bg-gray-100 text-zinc-900',
        showSocialIcons: false,
        image: 'assets/img/projects/hamster.png'
      }
    }
  }
  /**
   * Set data on home page
   */
  getHomeData = () => {

    return {
      seo: {
        seoTitle: `Creamos productos digitales en ${this.dataCity}`,
        seoExcerpt: 'En OSVI amamos a los animales tanto como tú. Ofrecemos servicios veterinarios de alta calidad con tecnología avanzada y un equipo de especialistas apasionados por la salud y el bienestar animal. Desde consultas generales hasta cirugías especializadas, en OSVI cuidamos de tu mascota como si fuera de nuestra familia. ¡Ven y descubre cómo podemos ayudarte a mantener a tu mejor amigo feliz y saludable!',
        seoKeywords: 'agencia digital, contenido redes, sitios web, marketing digital',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'items-center',
        useImage: {
          show: false,
          imageUrl: 'assets/img/projects/white_dog_blue_bg.png',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: 'assets/img/projects/bg_home_chihuahua_dog.jpg',
          lights: false,
          classes: 'bg-[#BED1CB] bg-cover bg-center bg-no-repeat ',
        },
        content: {
          shortLine: null,
          title: `Salud y felicidad para tus mascotas`,
          excerpt: `En OSVI cuidamos de la salud de tus mascotas con amor y experiencia. Contamos con servicios de medicina, cirugía y hospitalización. ¡Confía en nosotros para el bienestar de tus compañeros peludos!`,
          classesTitle: `text-white text-center ${this.titleSize} animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          buttons: [
            {
              id: 0,
              title: 'Agendar una cita',
              ui: 'bg-black font-semibold text-white shadow-sm hover:bg-zinc-900 animate__animated animate__zoomIn',
              redirect: '/contacto',
              icon: 'fa fa-whatsapp',
            },
            {
              id: 1,
              title: 'Llamar al 7222819532',
              ui: 'border-2 border-white font-semibold text-white shadow-sm  animate__animated animate__zoomIn',
              redirect: 'tel:7222819532',
              icon: 'fa fa-phone',
            }
          ],
          switchSkinTextColor: 'text-zinc-900',
        },
        centerColumn: null,
        containerClasses: 'md:grid md:grid-cols-2 flex h-screen items-center',
        naturalPositionColumn: false,
        textAlign: 'text-center',
        showSeparator: null,
      },
      overview: [
        {
          classesColumn: 'pb-6',
          title: 'Cuida la salud de tu mascota con nuestros servicios veterinarios integrales.',
          classesTitle: `${this.subtitleSize}`,
          description: `
          <h3 class="text-lg mb-2">¡Confía en nosotros para el mejor cuidado! 🐾🩺</h3>
          <p>
          OSVI es una clínica especializada en medicina de alta complejidad. Ofrecemos servicios de diagnóstico y tratamiento de enfermedades de la retina, oído y neurología, utilizando tecnología de última generación.</p>
          <ul class="hide-list-icon">
          <li class="lg:text-2xl"><i class="fa fa-paw mr-3" aria-hidden="true"></i> Desparasitación</li>
          <li class="lg:text-2xl"><i class="fa fa-paw mr-3" aria-hidden="true"></i> Vacunación</li>
          <li class="lg:text-2xl"><i class="fa fa-paw mr-3" aria-hidden="true"></i> Consultas</li>
          <li class="lg:text-2xl"><i class="fa fa-paw mr-3 " aria-hidden="true"></i> Cirugía</li>
          </ul>`,
          // imageOther: 'assets/img/default/bg-default.jpg',
          switchBackground: 'bg-white text-zinc-900',
          lottie: null,
          imageOther: 'assets/img/projects/perro-1.jpeg',
          button: 'Ver más de Medicina',
          buttonStyle: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
          buttonUrl: '/medicina',
        },
        {
          classesContainer: '',
          classesColumn: 'pb-4',
          customColumn1: 'bg-white text-zinc-900 px-6 text-center',
          customColumn2: '',
          title: 'Servicios Veterinarios en Toluca',
          classesTitle: `${this.subtitleSize}`,
          description: `
          <p>En OSVI amamos a los animales tanto como tú. Ofrecemos servicios veterinarios de alta calidad con tecnología avanzada y un equipo de especialistas apasionados por la salud y el bienestar animal. Desde consultas generales hasta cirugías especializadas, en OSVI cuidamos de tu mascota como si fuera de nuestra familia.
           </p>
           <p class="font-bold text-center">¡Ven y descubre cómo podemos ayudarte a mantener a tu mascota feliz y saludable!</p>
          `,
          switchBackground: 'bg-white text-zinc-900',
          imageOtherTitle: '<strong>19 AÑOS</strong>',
          imageOtherTitleClasses: 'text-7xl',
          imageOtherSubTitleClasses: `text-3xl`,
          imageOther: 'assets/img/projects/cat-1.jpeg',
          imageOtherSubTitle: 'de experiencia al cuidado de tu mascota.',

          imageOtherClasses: 'border border-8 border-white h-100',
          lottie: null,
          button: 'Conoce nuestra ubicación',
          buttonStyle: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
          buttonUrl: '/contacto',
          buttonIcon: 'fa fa-map-marker',
        },
      ],
      slider: [
        {
          imageUrl: 'assets/img/projects/dog_food.png',
          name: 'Nutrición premium para mascotas saludables',
          service: 'Nutrición',
          url: '/centro-nutricional',
        },
        {
          imageUrl: 'assets/img/projects/dog_spa.png',
          name: 'Belleza y cuidado para tu mascota.',
          service: 'Estética & Spa',
          url: '/estetica-y-spa',

        },
        {
          imageUrl: 'assets/img/projects/cat_wear.png',
          name: 'Estilo y confort para tu mascota.',
          service: 'Boutique',
          url: '/boutique',

        },
        {
          imageUrl: 'assets/img/projects/dog_laying.png',
          name: 'Cuidado confiable mientras estás fuera.',
          service: 'Pensión',
          url: '/pension',
        },
        {
          imageUrl: 'assets/img/projects/fish_gold.png',
          name: 'Acuario. Compra de peces y accesorios.',
          service: 'Boutique',
          url: '/boutique',

        },
        {
          imageUrl: 'assets/img/projects/rabbit_laying.png',
          name: 'Nutrición especializada para mascotas exóticas.',
          service: 'Nutrición',
          url: '/centro-nutricional',
        },
      ],
      callToAction: [
        {
          switchBackground: 'bg-white text-zinc-900',
          orderColumn1: 'order-2',
          useBackground: 'assets/img/projects/pug_golden.jpg',
          useBackgroundclasses: 'bg-cover bg-repeat-y flex',
          title: 'Descuentos para la comunidad UAEMEX',
          classesTitle: `${this.subtitleSize}`,
          description: `<p>La Veterinaria OSVI se enorgullece en anunciar su convenio de descuentos exclusivo para la comunidad UAMEX.
          Ahora, los estudiantes, profesores y personal administrativo de la UAMEX podrán disfrutar de descuentos especiales en productos y servicios de la clínica veterinaria líder en Toluca. 
          Desde servicios médicos hasta alimentación y accesorios para mascotas, OSVI de Toluca es la opción perfecta para el cuidado de su mascota.</p>`,
          button: 'Ver descuentos',
          buttonExternal: false,
          buttonUrl: '/convenio-uaemex',
          buttonIcon: 'fa fa-long-arrow-right '
        },
        {
          switchBackground: 'bg-white text-zinc-900',
          image: 'assets/img/projects/vet_online.png',
          title: 'Dr. OSVI Online',
          classesTitle: `${this.subtitleSize}`,
          description: `<p>La Asesoría Veterinaria Médica Online es un servicio en línea que proporciona atención y consejos médicos a distancia a dueños de mascotas. Los veterinarios brindan información y orientación para el cuidado de la salud de las mascotas a través de consultas virtuales, chats, correos electrónicos y videollamadas.</p>`,
          button: 'Solicitar asesoría médica ahora',
          buttonExternal: true,
          buttonUrl: 'https://api.whatsapp.com/send?phone=+527222819532&text=Hola,%20¿Me%20ayudan%20con%20una%20cita%20para%20mi%20mascota?',
          buttonIcon: 'fa fa-long-arrow-right '
        },
      ],
      brands: {
        switchBackground: 'bg-white text-zinc-900',
        introTitle: `Descubre los alimentos más saludables <br>para tu mascota.<br> 🐶🐱🐟🐰🐢`,
        classesTitle: 'text-4xl',
        brands: [
          {
            imageUrl: 'assets/img/brand/excellent.png',
            businessCategory: 'nutrición canina y felina'
          },
          {
            imageUrl: 'assets/img/brand/hills.png',
            businessCategory: 'nutrición canina y felina'
          },
          {
            imageUrl: 'assets/img/brand/mazuri.png',
            businessCategory: 'nutrición animales exóticos'
          },
          {
            imageUrl: 'assets/img/brand/tetra.png',
            businessCategory: 'nutrición para acuáticos'
          },
          {
            imageUrl: 'assets/img/brand/wardley.png',
            businessCategory: 'nutrición para acuáticos'
          },

        ]
      },
    }
  }

  /**
   * Set data on contact page
   */
  getContactData = () => {
    return {
      seo: {
        seoTitle: 'Asesoria Veterinaria Médica Online',
        seoExcerpt: 'La Asesoría Veterinaria Médica Online es un servicio en línea que proporciona atención y consejos médicos a distancia a dueños de mascotas. Los veterinarios brindan información y orientación para el cuidado de la salud de las mascotas a través de consultas virtuales, chats, correos electrónicos y videollamadas.',
        seoKeywords: 'Consulta veterinaria en línea en Toluca, México, Asesoría Veterinaria Médica Online, Cuidado de la salud de las mascotas',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'gap-4 text-zinc-900',
        showcaseOrder: 'lg:order-last sm:order-first',
        useImage: {
          show: false,
          imageUrl: 'assets/img/brand/because.svg',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          classes: 'bg-indigo-100',
        },
        content: {
          title: 'Iniciemos un <span class="mark">proyecto</span>',
          excerpt: 'Todas las cosas buenas comienzan con "Hola". Envíanos un mensaje y hagamos de tu proyecto algo increíble.',
          classesTitle: 'text-zinc-900 text-4xl text-center lg:text-6xl',
          buttons: [
            {
              id: 0,
              title: 'Mándanos un email',
              ui: 'bg-red-600 font-semibold text-white shadow-sm hover:bg-red-700 animate__animated animate__zoomIn',
              redirect: 'mailTo:hola@wap.com.mx',
              icon: 'fa fa-envelope',
            },
            {
              id: 1,
              title: 'Mándos un mensaje',
              redirect: 'https://api.whatsapp.com/send?phone=+527222819532&text=Hola,%20¿Me%20ayudan%20con%20una%20cita%20para%20mi%20mascota?',
              ui: ' font-semibold text-zinc-900 bg-green-300 animate__animated animate__zoomIn',
              icon: 'fa fa-whatsapp',
            }
          ],
          switchSkinTextColor: 'text-zinc-900',
        },

        centerColumn: false,
        naturalPositionColumn: null,
        textAlign: 'text-center',
        showSeparator: null,
      },
    }
  }




}


