import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  /**
  * Form type
  */
  formEmail: FormGroup;
  /**
   * Hold a string value
   */
  @Input() classesForm: string;
  /**
   * Event emitter for button
   */
  @Output() eventSend: EventEmitter<string> = new EventEmitter;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm = () => {
    const regexEmail: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.formEmail = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(regexEmail)]),
    });
  }

  get email() {
    return this.formEmail.controls.email;
  }

  /**
   * Rest the form
   */
  resetForm = () => {
    this.formEmail.reset();
  }
  /**
   * Handle click 
   * @param url string for a url
   */
  handleClick = () => {
    this.eventSend.emit(this.formEmail.value);
  }


}
