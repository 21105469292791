// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCKw2CgtySOnGdKvGpPAFEyh_yvDxnkdiw",
    authDomain: "sahper.firebaseapp.com",
    databaseURL: "https://sahper.firebaseio.com",
    projectId: "shaper",
    storageBucket: "sahper.appspot.com",
    messagingSenderId: "818671032667",
    appId: "1:818671032667:web:aabfdf514fa98846f9e27e",
    measurementId: "G-DFKVGKHDSX",
  },
  // neuboxApiUrl: '/api',
  url: 'https://wap.com.mx/',
  mdbUrl: 'https://api.themoviedb.org/',
  mdbApiKey: '24a6aec0c19c8e8dab02629a1a8dc1ec',
  mdbImageUrl: 'https://image.tmdb.org/t/p/w500',
  // sendgridApi: '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',
  emailJsApi: 'user_rRjNSgFu5HZfjGDgILRx9',
  geoApi: 'AIzaSyBC-zEPZS0e7gDLu1vBMRsYHtGRs4Tx8V0',
  // neuboxApiKey: "f66dd367120b4dbe73ba0af7a362e96f413c4a20",
  // neuboxApSecret: "d25df0b81fd87e98f28c667a8f4ed6b1c7d99280ad339741d27e684b262b6424",
  // neuboxUserEmail: "bXkuem9tYmllLm1pbmRAaG90bWFpbC5jb20=",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
