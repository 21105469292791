import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrivacyService {

  getPrivacyData = () => {
    return {
      showcase: {
        showcaseColumns: 'w-1/2',
        useImage: {
          show: false,
          imageUrl: 'assets/img/brand/because.svg',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          classes: 'bg-blue-300',
        },
        content: {
          classesTitle: `text-zinc-900 text-center text-4xl animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          title: 'Pólitica de privacidad',
          description: `
        <h4>Antes de usar nuestros sitios, lea atentamente esta política de privacidad</h4>
  
        <p>En cumplimiento con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la "Ley") y su reglamento, OSVI Clínica Veterinaio ("la empresa") con domicilio en Calle Laguna del Volcán 349, El Seminario Primera Secc, 50170 Toluca de Lerdo, Méx., hace de su conocimiento el presente aviso de privacidad.</p>
  
        <p>Información recopilada: La empresa recopila información personal de sus clientes, como nombres, apellidos, domicilios, teléfonos, correos electrónicos y datos de sus mascotas. Esta información es utilizada para proporcionar servicios veterinarios y para informar a los clientes acerca de promociones y nuevos servicios.</p>
  
        <p>Uso de la información: La empresa utilizará la información personal de sus clientes únicamente para los fines específicos mencionados en este aviso. En ningún caso se venderá, rentará o cederá información personal a terceros sin el consentimiento expreso del titular de la información.</p>
  
        <p>Medidas de seguridad: La empresa cuenta con medidas de seguridad físicas, electrónicas y administrativas para proteger la información personal recopilada contra robo, pérdida, acceso no autorizado, divulgación, alteración y destrucción.</p>
        <h4 class="mt-6">¿Cómo utilizamos la información recopilada de los visitantes en línea?</h4>
        <p>Antes de enviarnos cualquier información personal, tenga en cuenta que cualquier información recopilada en nuestros 
        Sitios se puede utilizar en conjunto para la investigación y el desarrollo relacionados con nuestros Sitios y/o 
         para el desarrollo futuro del sitio y, si nos lo solicita, para enviarle promociones. En particular, 
         podemos utilizar la información recopilada sobre usted para los siguientes propósitos: </p>
         <ul>
          <li>Monitorear el interés en nuestra gama de productos y ayudarnos a adaptar el contenido de nuestros Sitios a sus necesidades 
          mediante la recopilación de información sobre sus preferencias mediante el seguimiento de patrones de visitas a la página 
          en nuestros Sitios</li>
          <li>Para crear un perfil relacionado con usted con el fin de mostrarle el contenido que podría ser de su 
          interés y mostrar el contenido de acuerdo con sus preferencias</li>
          <li>En circunstancias en las que haya indicado que desea recibir información adicional de nuestra Empresa</li>
         </ul>
         <h4 class="mt-6">No recabamos Datos sensibles</h4>
            <ul class="list">
              <li>Origen racial</li>
              <li>Religión</li>
              <li>Preferencia sexual</li>
              <li>Estado de salud</li>
              <li>Entre otros</li>
            </ul>
         <h4 class="mt-6">Cookies</h4>
         <p>Este sitio utiliza cookies, pequeños archivos de texto que se colocan en su navegador para ayudarnos a brindar 
         una mejor experiencia de usuario. En general, las cookies se utilizan para conservar las preferencias del usuario, almacenar 
         información para cosas como carritos de compras y proporcionar datos de seguimiento anónimos a aplicaciones de terceros como 
         Google Analytics. Como regla general, las cookies mejorarán su experiencia de navegación. Sin embargo, es posible que prefiera 
         deshabilitar las cookies en este sitio y en otros. La forma más eficaz de hacerlo es deshabilitar las cookies en su navegador. 
         Sugerimos consultar la sección de Ayuda de su navegador o echar un vistazo al sitio web 
         <a href="http://www.aboutcookies.org/" target="_blank">Acerca de las cookies</a>, que ofrece orientación 
         para todos los navegadores modernos.</p>
         <p>Usamos las siguientes cookies:</p>
         <ul class="list">
         <li>Tipo de navegador</li>
         <li>Tiempo que permanece en la página</li>
         <li>Los vínculos que visitó en nuestro sitio</li>
         <li>Dirección IP</li>
         <li>URL que visitó antes de llegar a nuestro sitio</li>
         </ul>
        <p>Puede bloquear las cookies activando la configuración en su navegador que le permite rechazar la configuración de todas o algunas cookies. 
        Sin embargo, si utiliza la configuración de su navegador para bloquear todas las cookies (incluidas las cookies esenciales), es posible que 
        no pueda acceder a todo o parte de nuestro sitio.</p>
  
        <h4 class="mt-6">Protección de la información</h4>
        <p>Hemos implementado procedimientos físicos, electrónicos y administrativos para salvaguardar y ayudar a prevenir el acceso no autorizado, 
        mantener la seguridad de los datos y utilizar correctamente la información que recopilamos en línea. La Empresa aplica salvaguardas de 
        seguridad adecuadas a la sensibilidad de la información, como retener la información en instalaciones seguras y hacer que la información 
        personal sea accesible solo para los empleados autorizados cuando la necesiten.</p>
  
        <p>La información personal que comparte con nosotros se almacena en nuestros servidores de base de datos en los centros de datos de la 
        Empresa (en México), o es alojada por terceros que han celebrado acuerdos con nosotros que les exigen que cumplan con nuestra 
        Política de privacidad.</p>
  
        <p>Si modificamos nuestra Política de privacidad, cualquier cambio se publicará en esta página de nuestro Sitio para que siempre esté 
        informado de la información que recopilamos sobre usted, cómo la usamos y las circunstancias bajo las cuales podemos divulgarla.</p>
  
        <h2>Aceptación de nuestra política de privacidad</h2>
        <p>Al usar este Sitio o cualquier otro Sitio de La Empresa o anuncios publicitarios interactivos, usted manifiesta su 
        aceptación de nuestra Política de Privacidad y se adhiere a los términos y condiciones publicados en el Sitio. Al enviar 
        su información, acepta que se regirá por nuestra Política de privacidad.</p>
  
        <h3>Usted puede presentar sus quejas y denuncias por el tratamiento indebido de sus datos personales</h3>
        <p>Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados o de 
        nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones 
        previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia 
        correspondiente ante el IFAI, para mayor información visite <a href="http://www.ifai.org.mx" target="_blank">www.ifai.org.mx</a>
        </p>
        <p>Actualizado 11/04/2023</p>
        `,
          classes: 'lg:text-4xl ',
          switchSkinTextColor: 'text-zinc-900',
        },
        centerColumn: true,
        naturalPositionColumn: true,
        textAlign: 'text-left',
        showSeparator: null,
      }
    }
  }
}