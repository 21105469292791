import { Component, Input } from '@angular/core';
import { FeaturesModel } from '../../models/features.model';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html'
})
export class FeaturesComponent {

  @Input() backgroundImage: string;

  @Input() featureData: FeaturesModel;

  @Input() cardProperty: string;


  constructor() {

  }

}
