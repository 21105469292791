import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';

import { DataService } from '../../../shared/services/utils/data/data.service';

@Component({
  selector: 'app-demos-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {

  data: any;
  navbar: HTMLElement;

  constructor(
    private readonly element: ElementRef,
    public location: Location,
    private readonly dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.data = this.dataService.getCommonData();
    this.navbar = this.element.nativeElement.children[0].children[0];
  }

}
