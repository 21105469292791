import { Component, OnInit } from '@angular/core';

import { DataService } from '../../../../shared/services/utils/data/data.service';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../../../seo.service';
import { ServicesDataService } from '../../../../shared/services/utils/data/services.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  /**
 * Set showcase data
 */
  data: any;

  constructor(
    private readonly servicesService: ServicesDataService,
    private readonly dataService: DataService,
    private readonly title: Title,
    private readonly seoService: SeoService
  ) { }

  ngOnInit(): void {
    this.data = this.servicesService.getServicesData();
    this.seoService.setCanonicalURL();
    this.seoService.updateMetaInformationPage(this.servicesService.getServicesData());
    this.titleConfig();
  }

  titleConfig = () => {
    const titlePage = this.data.showcase.title;
    const appName = this.dataService.getCommonData().common.companyName;
    this.title.setTitle(`${appName} ${titlePage}`);
  }
}
