import { Component, OnInit } from '@angular/core';
import { PrivacyService } from '../../../../shared/services/utils/data/privacy.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
})
export class PrivacyComponent implements OnInit {
  data: any;

  constructor(
    private readonly privacyService: PrivacyService,
    private readonly title: Title
  ) { }


  ngOnInit(): void {
    const titlePage = this.privacyService.getPrivacyData().showcase.content.title;
    this.title.setTitle(`OSVI Clínica Veterinaria | ${titlePage}`);
    this.data = this.privacyService.getPrivacyData();
  }


}
