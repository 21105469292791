import { Component, OnInit } from '@angular/core';
import { NutricionDataService } from '../../../../../shared/services/utils/data/nutricion.service';
import { DataService } from '../../../../../shared/services/utils/data/data.service';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../../../../seo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nutricion',
  templateUrl: './nutricion.component.html',
  styleUrls: ['./nutricion.component.scss']
})
export class NutricionComponent implements OnInit {
  data: any;
  constructor(
    private readonly nutricionData: NutricionDataService,
    private readonly dataService: DataService,
    private readonly title: Title,
    private readonly seoService: SeoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.data = this.nutricionData.getNutricionData();
    this.seoService.setCanonicalURL();
    this.seoService.updateMetaInformationPage(this.nutricionData.getNutricionData());
    this.titleConfig();
  }
  titleConfig = () => {
    const titlePage = `${this.data.seo.seoTitle}`;
    const appName = this.dataService.getCommonData().common.companyName;
    this.title.setTitle(`${appName} ${titlePage}`);
  }
  goButtons = (): void => {

    this.router.navigateByUrl(this.nutricionData.getNutricionData().showcase.content.buttons[0].redirect);

  }
}
