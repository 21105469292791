import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { AngularFireDatabaseModule, AngularFireList } from '@angular/fire/database';
// import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ErrorComponent } from './components/error/error.component';
import { LayoutModule } from './modules/public/layout/layout.module';
import { SharedModule } from './shared/shared-module';
import { MaterialModule } from './material/material.module';
//import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { DataService } from './shared/services/utils/data/data.service';
import { SeoService } from './seo.service';
import { SenderService } from './shared/services/utils/data/sender.service';
import { NetworkInterceptor } from './shared/services/network.interceptor';
import { LoaderModule } from './components/loader/loader.module';


@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'osvi-app' }),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    SharedModule,
    CommonModule,
    LoaderModule,
    LayoutModule,
    MaterialModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: 'SeoService', useClass: SeoService },
    { provide: 'SenderService', useClass: SenderService },
    { provide: 'DataService', useClass: DataService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() { }

}
