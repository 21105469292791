
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as Email from 'emailjs-com';

import { DataService } from '../../services/utils/data/data.service';
import { environment } from '../../../../environments/environment';
import { DialogComponent } from '../dialog/dialog.component';


@Component({
  selector: 'app-form-contact',
  templateUrl: './form-contact.component.html',
})
export class FormContactComponent {

  data: any;
  /**
   * type of Form
   */
  form: FormGroup;

  /**
   * Validate to account
   */
  validForm = true;
  /**
   * show or hide category input
   */
  showInput = true;
  /**
   * Close modal
   */
  closeResult: string;
  /**
   * Validate to form
   */
  validateForm = true;
  /**
 * Define type of modal
 */
  // modal: NgbModalRef;

  // How find us channel
  Channel: any = ['Referido', 'Facebook', 'Google', 'Convenio UAEMEX']

  @ViewChild('classic') classic: ElementRef;
  @ViewChild('error') error: ElementRef;
  /**

/**
* event click to submit
*/
  @Output() eventSubmit: EventEmitter<void> = new EventEmitter;

  @Output() eventPhone: EventEmitter<number> = new EventEmitter;

  @Output() eventEmail: EventEmitter<string> = new EventEmitter;


  constructor(
    private readonly fb: FormBuilder,
    private readonly dataService: DataService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.data = this.dataService.getContactData();
    this.initForm();
  }

  initForm = () => {
    const regexEmail = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

    this.form = this.fb.group({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required, Validators.pattern(regexEmail)]),
      phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      message: new FormControl('', [Validators.required, Validators.minLength(10)]),
      howfindus: new FormControl('', [Validators.required]),
    })
  }

  changeFindUs(e: any) {
    this.howfindus?.setValue(e.target.value, {
      onlySelf: true
    })
  }


  get name() {
    return this.form.controls.name;
  }
  get email() {
    return this.form.controls.email;
  }
  get phone() {
    return this.form.controls.phone;
  }
  get message() {
    return this.form.controls.message;
  }

  get howfindus() {
    return this.form.get('howfindus');
  }

  resetForm = () => {
    this.form.reset();
  }

  onSubmit = () => {
    if (this.form.invalid) {
      return;
    }

    const templateParams = {
      from_name: this.form.value.name,
      to_name: 'WAP',
      toEmail: this.form.value.email,
      message_html: this.form.value.message,
      email: this.form.value.email,
      howfindus: this.form.value.howfindus,
      phone: this.form.value.phone,
    };

    Email.send('service_wdwifwf', 'template_2e5jix5', templateParams, environment.emailJsApi)
      .then(() => {
        this.dialog.open(DialogComponent, {
          data: {
            height: '400px',
            width: '600px',
            message: `Se envió con éxito`,
            buttonText: {
              cancel: 'Cerrar'
            }
          },
        });
        this.resetForm();
      }, function (err) {
        console.log('FAILED...', err);
      });
  }

}
