import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { ShowcaseComponent } from './components/showcase/showcase/showcase.component';
import { FeaturesComponent } from './components/features/features.component';
import { OverviewComponent } from './components/overview/overview.component';
import { CalltoactionComponent } from './components/calltoaction/calltoaction.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { TableListComponent } from './components/table-list/table-list.component';
import { AddFormComponent } from './components/add-form/add-form.component';
import { OnlyNumbersDirective } from './services/directives/onlyNumbers.directive';
import { IntroductionComponent } from './components/introduction/introduction/introduction.component';
import { DataService } from './services/utils/data/data.service';
import { BrandsListComponent } from './components/brands-list/brands-list.component';
import { LastCallComponent } from './components/last-call/last-call/last-call.component';
import { MaterialModule } from '../material/material.module';
import { DialogComponent } from './components/dialog/dialog.component';
import { ReturnComponent } from './components/return/return.component';
import { FormComponent } from './components/form/form.component';
import { FooterComponent } from './components/footer/footer.component';
import { CallPriceComponent } from './components/prices/call-price/call-price.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { FormContactComponent } from './components/form-contact/form-contact.component';

export function playerFactory() {
  return player;
}

const DECLARATIONS_EXPORTS = [
  ShowcaseComponent,
  FeaturesComponent,
  OverviewComponent,
  CalltoactionComponent,
  SlideshowComponent,
  TableListComponent,
  AddFormComponent,
  OnlyNumbersDirective,
  IntroductionComponent,
  BrandsListComponent,
  DialogComponent,
  LastCallComponent,
  ReturnComponent,
  FormComponent,
  FooterComponent,
  FormContactComponent,
  CallPriceComponent,
];


@NgModule({
  declarations: DECLARATIONS_EXPORTS,
  exports: DECLARATIONS_EXPORTS,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    DataService,
  ]
})
export class SharedModule { }
