import { Component, OnInit, PLATFORM_ID, Inject, } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import AOS from 'aos';

import { SeoService } from './seo.service';
import { LoadingService } from './shared/services/utils/loader/loader.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  schema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    'name': 'osvi.com.mx',
    'url': 'https://osvi.com.mx'
  };

  data: any;
  loading: boolean = false;
  title = 'osviWebApp';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly seoService: SeoService,
    public loader: LoadingService,
    private readonly router: Router,
  ) {
    setTimeout(() => {
      this.loader.hide()
    }, 2000);
    this.loader.show();
    this.seoService.initDefaultMetaInformation();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }



  ngOnInit() {
    this.aosAnimated();
    this.videoYoutube();
    this.seoService.initDefaultMetaInformation();
  }


  aosAnimated = () => {
    if (isPlatformBrowser(this.platformId)) {
      AOS.init({
        // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
        initClassName: 'aos-init', // class applied after initialization
        animatedClassName: 'aos-animate', // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 400, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: false, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
      });
    }
  }

  videoYoutube = () => {
    if (isPlatformBrowser(this.platformId)) {
      const tag = window.document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      window.document.body.appendChild(tag);
    }
  }
}
