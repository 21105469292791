import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PensionDataService {

  constructor() { }

  /**
   * Set data on services page
   */
  getPensionData = () => {
    return {
      seo: {
        seoTitle: 'Tu mascota merece lo mejor para hospedarse',
        seoExcerpt: 'En nuestra pensión, contamos con personal altamente capacitado para atender las necesidades de tu mascota. Nos aseguramos de que tengan suficiente ejercicio y juegos para mantenerlos felices y activos.',
        seoKeywords: 'Pensión de mascotas Toluca, Alojamiento para mascotas Toluca, Cuidado de mascotas Toluca, Hospedaje para perros Toluca, Guardería para gatos Toluca',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'items-center grid lg:grid-cols-2',
        showcaseOrder: '',
        imageClasses: 'rounded-full',
        useImage: {
          show: true,
          imageUrl: 'assets/img/projects/dog_smile.png',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          lights: false,
          classes: 'bg-cyan-500 bg-cover bg-center bg-no-repeat ',
        },
        content: {
          shortLine: 'Deja a tu mascota en las mejores manos',
          title: `Pensión`,
          excerpt: `Sabemos que dejar a tu compañero peludo en manos de alguien más puede ser estresante, por eso nos aseguramos de brindar un ambiente cómodo y seguro para que tu mascota se sienta como en casa mientras estás fuera.`,
          classesTitle: `text-white text-center text-6xl animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          buttons: [
            {
              id: 0,
              title: 'Agendar una cita',
              ui: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
              redirect: '/contacto',
              icon: 'fa fa-calendar-check-o',
            },
          ],
          switchSkinTextColor: 'text-zinc-200',
        },
        textAlign: 'text-center',
        showSeparator: null,
      },
      overview:
      {
        classesColumn: 'pb-6 pt-6',
        title: 'Tu mascota merece lo mejor',
        classesTitle: `text-3xl`,
        description: `En OSVI, nos enorgullece ofrecer un servicio de pensión integral para tu mascota. Además de brindarles una cama 
        cómoda y un ambiente seguro, también ofrecemos servicios adicionales como atención veterinaria, baños y peluquería para que tu 
        mascota regrese a casa luciendo y sintiéndose mejor que nunca.
        <br>
        <p class="mt-2">En nuestra pensión, contamos con personal altamente capacitado para atender las necesidades de tu mascota. Nos aseguramos de 
        que tengan suficiente ejercicio y juegos para mantenerlos felices y activos. 
        Además, nuestras instalaciones cuentan con un ambiente higiénico y confortable para asegurar la salud y bienestar de tu mascota.</p>
        `,
        // imageOther: 'assets/img/default/bg-default.jpg',
        switchBackground: 'bg-cyan-500 text-zinc-100',
        lottie: null,
        imageOther: 'assets/img/projects/pets_playing.png',
        imageOtherClasses: 'rounded-full',

      },
      brands: {
        switchBackground: 'bg-white text-zinc-900',
        introTitle: `Puedes solicitar alimentación premium <br>para tu mascota.<br> 🐶🐱🐟🐰🐢`,
        classesTitle: 'text-4xl',
        brands: [
          {
            imageUrl: 'assets/img/brand/excellent.png',
            businessCategory: 'nutrición canina y felina'
          },
          {
            imageUrl: 'assets/img/brand/hills.png',
            businessCategory: 'nutrición canina y felina'
          },
          {
            imageUrl: 'assets/img/brand/mazuri.png',
            businessCategory: 'nutrición animales exóticos'
          },
          {
            imageUrl: 'assets/img/brand/tetra.png',
            businessCategory: 'nutrición para acuáticos'
          },
          {
            imageUrl: 'assets/img/brand/wardley.png',
            businessCategory: 'nutrición para acuáticos'
          },

        ]
      },
      callToAction:
      {
        switchBackground: 'bg-white',
        orderColumn1: 'order-2',
        useBackground: 'assets/img/projects/cat_orange.png',
        useBackgroundclasses: 'bg-cover bg-repeat-y bg-center flex',
        title: '¿Estás buscando la mejor atención veterinaria para tus mascotas en Toluca?',
        classesTitle: `text-3xl lg:text-5xl text-zinc-900 lg:text-white`,
        description: `<p class="text-zinc-900 lg:text-white">Nuestro equipo de veterinarios altamente capacitados está dedicado a proporcionar la mejor atención médica para tus animales de compañía, desde perros y gatos hasta aves y reptiles. Contamos con una amplia gama de servicios que incluyen consultas médicas, cirugía, exámenes de diagnóstico, tratamiento de enfermedades, vacunaciones y más.</p>`,
        button: 'Contactar',
        buttonExternal: false,
        buttonUrl: '/contacto',
        buttonIcon: 'fa fa-long-arrow-right '
      }
    }
  }
}


