import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DialogComponent } from '../dialog/dialog.component';
import { OverviewModel } from '../../models/overview.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
})
export class OverviewComponent implements OnInit {
  /**
 * Set short description after the title
 */
  @Input() overviewData: OverviewModel;
  /**
   * Set custom overview
   */
  @Input() customOverview = false;
  /**
   * custom columns
   */
  @Input() customColumn1: string;
  /**
   * custom columns
   */
  @Input() customColumn2: string;
  /**
   * Set background
   */
  @Input() switchBackground: string;

  /**
  * Show video
  */
  @Input() showVideo: boolean;

  @Input() column2class: string;
  isShow = false;
  @Output() eventClick: EventEmitter<void> = new EventEmitter;

  options: AnimationOptions = {
    path: '/assets/animation.json',
  };

  urlLottie: string;

  closeResult: string;

  buttonUrlData: string;

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.urlLottie = this.overviewData.lottie;
    this.options = {
      ...this.options, // In case you have other properties that you want to copy
      path: this.urlLottie,
    };
  }

  showMore = () => {
    this.isShow = !this.isShow;
  }

  openDialog = () => {
    this.dialog.open(DialogComponent, {
      data: {
        height: '400px',
        width: '600px',
        message: this.overviewData.terms!.content,
        validityStart: this.overviewData.terms!.validityStart,
        validityEnd: this.overviewData.terms!.validityEnd,
        disclaimer: this.overviewData.terms!.disclaimer,
      },
    });
  }

  buttonUrl = () => {
    this.buttonUrlData = this.overviewData.buttonUrl!;
    this.router.navigateByUrl(this.buttonUrlData);
  }

  /**
 * Method to pass the url of the video
 * @param url path of the video
 * @returns secure path for the video
 */
  getVideoIframe = (url: string) => {
    let video, results;
    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
  }

}
