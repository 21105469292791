import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../shared/services/utils/data/data.service';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  data: any;
  constructor(
    private readonly router: Router,
    @Inject('DataService') private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.data = this.dataService.getCommonData();
  }

  goHome = () => {
    this.router.navigateByUrl('/');
  }
}
