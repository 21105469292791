import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AboutDataService {

  titleSize: string;
  subtitleSize: string;

  constructor() {
    this.titleSize = 'lg:text-6xl text-4xl mb-4';
    this.subtitleSize = 'lg:text-4xl text-3xl mb-4'
  }

  /**
   * Set data on about us page
   */
  getAboutUsData = () => {
    return {
      seo: {
        seoTitle: 'Somos OSVI',
        seoExcerpt: 'Somos una organización veterinaria integral vanguardista, que sea capaz de ofertar toda una gama de servicios profesionales relacionados a la salud, nutrición, estética y pensión.',
        seoKeywords: 'veterinria, veterinaria toluca, veterinario en toluca, clínica veterinaria toluca',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'items-center grid lg:grid-cols-2',
        showcaseOrder: '',
        imageClasses: 'rounded-full',
        useImage: {
          show: true,
          imageUrl: 'assets/img/projects/rabbit_blue.png',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          lights: false,
          classes: 'bg-blue-600 bg-cover bg-center bg-no-repeat ',
        },
        content: {
          shortLine: 'Nuestro reflejo, tu mascota',
          title: `Somos OSVI`,
          excerpt: `Somos una organización veterinaria integral vanguardista, que sea capaz de ofertar toda una gama de servicios profesionales relacionados a la salud, nutrición, estética y pensión.`,
          classesTitle: `text-white text-center text-6xl animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          buttons: [
            {
              id: 0,
              title: 'Agendar una cita',
              ui: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
              redirect: '/contacto',
              icon: 'fa fa-calendar-check-o',
            },
          ],
          switchSkinTextColor: 'text-zinc-200',
        },
        textAlign: 'text-center',
        showSeparator: null,
      },
      overview: [
        {
          classesColumn: 'px-8 lg:px-20 py-6',
          title: 'OSVI Clínica Veterinaria',
          classesTitle: `${this.subtitleSize}`,
          description: `<p>Somos una clínica veterinaria en Toluca con un enfoque integral y vanguardista, capaz de ofrecer una amplia gama de 
        servicios profesionales para la salud, nutrición, estética y pensión de tu mascota. Nuestro equipo de expertos veterinarios se dedica 
        a brindar una atención personalizada y de calidad, utilizando la tecnología y técnicas más avanzadas para el cuidado de tu compañero peludo.</p>
        <p>
        Contamos con servicios de atención médica preventiva, consultas especializadas, cirugías, hospitalización y cuidados intensivos, así como 
        también ofrecemos alimentación de alta calidad y servicios de estética canina y felina. Además, disponemos de un servicio de pensión donde 
        podrás dejar a tu mascota con tranquilidad y la certeza de que recibirá el cuidado adecuado. Si buscas una clínica veterinaria confiable y 
        profesional en Toluca, ¡no dudes en contactarnos!</p>
          `,
          switchBackground: 'bg-blue-700 text-white',
          lottie: null,
          imageOther: 'assets/img/projects/cat_gray.png',
          imageOtherClasses: 'rounded-full',
        },
        {
          classesContainer: '',
          classesColumn: '',
          customColumn1: 'bg-white text-zinc-900 px-6 text-center',
          customColumn2: '',
          title: 'Servicios Veterinarios en Toluca',
          classesTitle: `${this.subtitleSize}`,
          description: `
        <p>En OSVI amamos a los animales tanto como tú. Ofrecemos servicios veterinarios de alta calidad con tecnología avanzada y un equipo de especialistas apasionados por la salud y el bienestar animal. Desde consultas generales hasta cirugías especializadas, en OSVI cuidamos de tu mascota como si fuera de nuestra familia.
         </p>
         <p class="font-bold text-center">¡Ven y descubre cómo podemos ayudarte a mantener a tu mascota feliz y saludable!</p>
        `,
          switchBackground: 'bg-white text-zinc-900',
          imageOtherTitle: '<strong>19 AÑOS</strong>',
          imageOtherTitleClasses: 'text-5xl',
          imageOtherSubTitleClasses: `text-2xl`,
          imageOther: 'assets/img/projects/cat-1.jpeg',
          imageOtherSubTitle: 'de experiencia al cuidado de tu mascota.',

          imageOtherClasses: 'border border-8 border-white w-1/3',
          lottie: null,
          button: 'Conoce nuestra ubicación',
          buttonStyle: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
          buttonUrl: '/contacto',
          buttonIcon: 'fa fa-map-marker',
        },],
      slider: [
        {
          imageUrl: 'assets/img/fama/1.jpeg',
        },
        {
          imageUrl: 'assets/img/fama/2.jpeg',
        },
        {
          imageUrl: 'assets/img/fama/3.jpeg',
        },
        {
          imageUrl: 'assets/img/fama/4.jpeg',
        },
        {
          imageUrl: 'assets/img/fama/5.jpeg',
        },
        {
          imageUrl: 'assets/img/fama/6.jpeg',
        },
      ],
      callToAction:
      {
        switchBackground: 'bg-white text-white',
        orderColumn1: 'order-2',
        useBackground: 'assets/img/projects/dog_blue.png',
        useBackgroundclasses: 'bg-cover bg-repeat-y bg-center flex',
        title: '¿Estás buscando la mejor atención veterinaria para tus mascotas en Toluca?',
        classesTitle: `text-5xl`,
        description: `<p>Nuestro equipo de veterinarios altamente capacitados está dedicado a proporcionar la mejor atención médica para tus animales de compañía, desde perros y gatos hasta aves y reptiles. Contamos con una amplia gama de servicios que incluyen consultas médicas, cirugía, exámenes de diagnóstico, tratamiento de enfermedades, vacunaciones y más.</p>`,
        button: 'Contactar',
        buttonExternal: false,
        buttonUrl: '/contacto',
        buttonIcon: 'fa fa-long-arrow-right '
      }

    }
  }
}


