import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { SharedModule } from '../../../shared/shared-module';
import { HomeComponent } from '../sub-modules/home/home.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { ContactComponent } from '../sub-modules/contact/contact.component';
import { ServicesComponent } from '../sub-modules/services/services.component';
import { AboutUsComponent } from '../sub-modules/about-us/about-us.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { PrivacyComponent } from '../sub-modules/privacy/privacy.component';
import { MaterialModule } from '../../../material/material.module';
import { PensionComponent } from '../sub-modules/services/pension/pension.component';
import { BoutiqueComponent } from '../sub-modules/services/boutique/boutique.component';
import { EsteticaComponent } from '../sub-modules/services/estetica/estetica.component';
import { NutricionComponent } from '../sub-modules/services/nutricion/nutricion.component';
import { MedicinaComponent } from '../sub-modules/services/medicina/medicina.component';
import { ConvenioComponent } from '../sub-modules/convenio/convenio.component';


@NgModule({
  declarations: [
    LayoutComponent,
    HomeComponent,
    NavbarComponent,
    ContactComponent,
    ServicesComponent,
    AboutUsComponent,
    PrivacyComponent,
    PensionComponent,
    BoutiqueComponent,
    EsteticaComponent,
    NutricionComponent,
    MedicinaComponent,
    ConvenioComponent

  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    MaterialModule,
    SharedModule,
    RouterModule,
    HttpClientModule,
  ],
  providers: [

  ]
})

export class LayoutModule { }
