import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SenderService {


  constructor(
    private readonly http: HttpClient,
  ) { }
  /**
   * Set formData for a backend sender
   */
  // onSendServicePhp = (formData: FormData): Observable<any> => {
  //   const data: any = new FormData();
  //   data.append('formData', 'ok');
  //   for (const key in formData) {
  //     if (Object.prototype.hasOwnProperty.call(formData, key)) {
  //       const element = formData[key];
  //       data.append(key, element);
  //     }
  //   }
  //   return this.http.post<any>('https://static.wap.com.mx/back-form/sendEmail.php', data);
  // }

  saveAndSendMail(formData: FormData) {
    // const saveData = new FormData();
    // saveData.append('email', emailOfUser);
    const saveData = {
      email: formData
    };

    this.http.post<{ message: string }>('http://localhost:27017/subscribe/', saveData).subscribe((respaanse) => {
      console.log(respaanse.message);
    });
  }
}
