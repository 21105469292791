import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CalltoActionModel } from '../../models/calltoaction.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calltoaction',
  templateUrl: './calltoaction.component.html',
  styleUrls: ['./calltoaction.component.scss']
})
export class CalltoactionComponent {

  buttonUrlData: string;
  /**
   * Set background color
   */
  @Input() backgroundColor: string;
  /**
   * Set model to call to action
   */
  @Input() callToActionData: CalltoActionModel;

  @Output() goTo = new EventEmitter<any>();

  constructor(
    private readonly router: Router,
  ) { }

  buttonUrl = () => {
    this.buttonUrlData = this.callToActionData.buttonUrl!;
    this.router.navigateByUrl(this.buttonUrlData);
  }

  buttonUrlExternal = () => {
    this.buttonUrlData = this.callToActionData.buttonUrl!;
    window.open(this.buttonUrlData, '_blank');
  }

}
