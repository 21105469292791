import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { SeoService } from '../../../../seo.service';
import { ContactDataService } from '../../../../shared/services/utils/data/contact.service';
import { DataService } from '../../../../shared/services/utils/data/data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  /**
   * Get contact data from service
   */
  data: any;
  /**
   * Shall we display the form?
   */
  showActiveForm = true;

  constructor(
    private readonly dataService: DataService,
    private readonly contactService: ContactDataService,
    private readonly title: Title,
    private readonly seoService: SeoService
  ) { }
  /**
   * This method is responsible for initializing the component, setting the data, updating the SEO of the page, and updating the title of the page.
   */
  ngOnInit(): void {
    this.data = this.contactService.getContactData();
    this.seoService.setCanonicalURL();
    this.seoService.updateMetaInformationPage(this.contactService.getContactData());
    this.titleConfig();
  }
  /**
   * This function most likely sets the title of a page or a section in an application
   */
  titleConfig = () => {
    const titlePage = this.data.showcase.title;
    const appName = this.dataService.getCommonData().common.companyName;
    this.title.setTitle(`${appName} ${titlePage}`);
  }
  /**
   * This function is used as a click event handler for buttons and it opens different URLs depending on which button was clicked.
   * @param event 
   */
  clickButtons = (event: any): any => {
    if (event.index === 0) {
      window.open(this.contactService.getContactData().showcase.content.buttons[0].redirect, '_self');
    } else {
      window.open(this.contactService.getContactData().showcase.content.buttons[1].redirect);
    }
  }
}
