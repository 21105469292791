import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ServicesDataService {

  titleSize: string;
  subtitleSize: string;

  constructor() {
    this.titleSize = 'lg:text-6xl text-4xl mb-4';
    this.subtitleSize = 'lg:text-4xl text-3xl mb-4'
  }


  /**
   * Set data on services page
   */
  getServicesData = () => {
    return {
      seo: {
        seoTitle: 'Tu departamento creativo',
        seoExcerpt: 'Hemos refinado nuestro proceso de diseño y desarrollo a través de la experiencia e invaluables oportunidades de trabajar con empresas alrededor del mundo de todo tipo y tamaños.',
        seoKeywords: 'estrategia digital, anuncios publicitarios, contenido digital, sitios web, tiendas online, seo, branding',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: '',
        showcaseOrder: '',
        useImage: {
          show: false,
          imageUrl: '',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          classes: 'bg-pink-100',
        },
        content: {
          title: `Tu marca merece un socio <br> <span class="mark">estratega y creativo</span>`,
          excerpt: `Hemos refinado nuestro proceso de diseño y desarrollo a través de la experiencia e invaluables oportunidades de <br>trabajar con empresas alrededor del mundo de todo tipo y tamaños.`,
          classesTitle: `text-zinc-900 text-center ${this.titleSize}`,
          classesExcerpt: '',
          buttons: [

          ],
          switchSkinTextColor: 'text-zinc-900',
        },
        textAlign: 'text-center',
        showSeparator: null,
      },

      feature: {
        switchBackground: 'bg-yellow-100 text-zinc-900',
        title: 'Conoce nuestros servicios',
        classesTitle: `${this.subtitleSize}`,
        description: 'Nuestra firma de estrategia y desarrollo tiene dos objetivos muy simples: Otimizar la experiencia de cada usuario y crear un impacto medible para todos nuestros clientes.',
        items: [
          {
            cardProperty: 'col-md-4 text-center',
            iconImage: 'assets/img/icons/line/target.svg',
            iconTagline: 'Estrategia digital',
            iconDesc: `Observamos a sus principales competidores para evaluar sus esfuerzos de marketing y comprender 
            las tendencias de la industria para comunicarnos con su audiencia de manera más efectiva.`,
          },
          {
            cardProperty: 'col-md-4 text-center',
            iconImage: 'assets/img/icons/line/growth.svg',
            iconTagline: 'Anuncios publicitarios',
            iconDesc: `Los Anuncios en Google Ads y Facebook Ads son los canales preferidos para realizar campañas dirigidas a obtener prospectos y enviarlos a tráfico en los Embudos de Venta.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },
          {
            cardProperty: 'col-md-4 text-center',
            iconImage: 'assets/img/icons/line/social-media.svg',
            iconTagline: 'Contenido digital',
            iconDesc: `Esta fase permite que todos experimenten un diseño completamente funcional y aborden 
            las inquietudes antes de pulir todo para la implementación.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },
          {
            cardProperty: 'col-md-4 text-center',
            iconImage: 'assets/img/icons/line/web.svg',
            iconTagline: 'Sitios web',
            iconDesc: `Observamos a sus principales competidores para evaluar sus esfuerzos de marketing y comprender 
            las tendencias de la industria para comunicarnos con su audiencia de manera más efectiva.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },
          {
            cardProperty: 'col-md-4 text-center',
            iconImage: 'assets/img/icons/line/online-shopping.svg',
            iconTagline: 'Tiendas online',
            iconDesc: `En esta etapa revisamos el trabajo conceptual inicial para expandir 
            el concepto de wireframes que desarrollamos durante nuestra fase de estrategia.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },
          {
            cardProperty: 'col-md-4 text-center',
            iconImage: 'assets/img/icons/line/app.svg',
            iconTagline: 'Aplicaciones móviles',
            iconDesc: `En esta etapa revisamos el trabajo conceptual inicial para expandir 
            el concepto de wireframes que desarrollamos durante nuestra fase de estrategia.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },
          {
            cardProperty: 'col-md-4 text-center',
            iconImage: 'assets/img/icons/line/statistics.svg',
            iconTagline: 'Posicionamiento web (SEO)',
            iconDesc: `Migramos el nuevo sitio web al servidor de producción y lo ejecutamos a su ritmo durante nuestras pruebas de usuario multiplataforma.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },
          {
            cardProperty: 'col-md-4 text-center',
            iconImage: 'assets/img/icons/line/brand.svg',
            iconTagline: 'Branding',
            iconDesc: `Una vez implementado su sitio web, ofrecemos controles mensuales del estado del sitio web, supervisión y soporte.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          },
          {
            cardProperty: 'col-md-4 text-center',
            iconImage: 'assets/img/icons/line/design.svg',
            iconTagline: 'Diseño gráfico',
            iconDesc: `Una vez implementado su sitio web, ofrecemos controles mensuales del estado del sitio web, supervisión y soporte.`,
            // iconBtnIcon: 'fa fa-chevron-right',
            // iconBtn: 'See our work',
            // iconBtnUrl: 'https://api.whatsapp.com/send?phone=+527224417125&text=hola,%20qué%20tal?',
          }
        ]
      },
      callToAction: {
        switchBackground: 'bg-green-100 text-zinc-900',
        title: 'Comencemos un proyecto',
        classesTitle: `${this.subtitleSize}`,
        description: `<p>Abordar un proyecto digital puede ser una tarea compleja sin la experiencia adecuada. <br>
        Nuestro equipo puede ayudarlo a planificar y ejecutar su próximo proyecto.</p>`,
        button: 'Inciar un proyecto',
        buttonUrl: '/contacto',
        buttonIcon: 'fa fa-long-arrow-right'
      },
    }
  }
}


