import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private readonly appTitle = 'OSVI Clínica Veterinaria';
  private readonly appDescription = 'En OSVI amamos a los animales tanto como tú. Ofrecemos servicios veterinarios de alta calidad con tecnología avanzada y un equipo de especialistas apasionados por la salud y el bienestar animal. Desde consultas generales hasta cirugías especializadas, en OSVI cuidamos de tu mascota como si fuera de nuestra familia. ¡Ven y descubre cómo podemos ayudarte a mantener a tu mejor amigo feliz y saludable!';
  private readonly appImage = 'assets/img/og-default.jpg';

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private readonly metaTagService: Meta,
  ) { }

  initDefaultMetaInformation = () => {
    this.metaTagService.addTags([
      { charset: 'UTF-8' },
      { name: 'author', content: 'WAP Agencia Digital' },
      { name: 'robots', content: 'index, follow' },
      { name: 'description', content: `${this.appDescription}` },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:image', content: `${this.appImage}` },
      { name: 'twitter:title', content: `${this.appTitle}` },
      { name: 'twitter:creator', content: '@wapmexico' },
      { name: 'og:title', content: `${this.appTitle}` },
      { name: 'og:description', content: `${this.appDescription}` },
      { name: 'og:image', content: `${this.appImage}` },
      { name: 'og:type', content: 'website' },
      { name: 'og:locale', content: 'es_MX' },
    ]);
  }

  updateMetaInformationPage = (data: any) => {
    this.metaTagService.updateTag(
      { name: 'keywords', content: `${data.seo.seoKeywords}` },
    );

    this.metaTagService.updateTag(
      { name: 'description', content: `${data.seo.seoExcerpt}` },
    );

    this.metaTagService.updateTag(
      { name: 'twitter:image', content: `${environment.url}${data.seo.seoImage}` },
    )

    this.metaTagService.updateTag(
      { name: 'twitter:title', content: `${data.seo.seoTitle}` },
    )

    this.metaTagService.updateTag(
      { name: 'og:title', content: `${data.seo.seoTitle}` },
    )
    this.metaTagService.updateTag(
      { name: 'og:description', content: `${data.seo.seoExcerpt}` },
    )
    this.metaTagService.updateTag(
      { name: 'og:image', content: `${environment.url}${data.seo.seoImage}` },
    )
  }


  setCanonicalURL = (url?: string) => {
    const canURL = url === undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

}
