import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

import { SenderService } from '../../services/utils/data/sender.service';

@Component({
  selector: 'app-add-form',
  templateUrl: './add-form.component.html',
  styleUrls: ['./add-form.component.scss']
})
export class AddFormComponent implements OnInit {
  /**
   * type of Form
   */
  form: FormGroup;
  /**
   * Validate to account
   */
  validForm = true;
  /**
   * show or hide category input
   */
  showInput = true;
  /**
   * event click to return list
  */
  @Output() eventClick: EventEmitter<void> = new EventEmitter;


  constructor(
    private readonly fb: FormBuilder,
    private senderService: SenderService,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm = () => {
    const hexa = '^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$';
    this.form = this.fb.group({
      id: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(hexa)]),
      title: new FormControl('', [Validators.required, Validators.minLength(3)]),
      subtitle: new FormControl('', [Validators.required, Validators.minLength(3)]),
      description: new FormControl('', [Validators.required, Validators.minLength(100)]),
      category: new FormControl('', [Validators.required, Validators.minLength(4)])
    })
  }

  onSubmit = () => {
    this.senderService.saveAndSendMail(this.form.value);
  }

  toogleCategory = () => this.showInput = !this.showInput;

  get value() {
    return this.form.controls;
  }

  returnList = () => this.eventClick.emit();

}
