import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class BoutiqueDataService {

  constructor() { }

  /**
   * Set data on services page
   */
  getBoutiqueData = () => {
    return {
      seo: {
        seoTitle: 'Boutique para mascotas en Toluca',
        seoExcerpt: 'En OSVI así como la salud es integral, también lo es el esparcimiento de tu mascota, por ello contamos con una excelente gama de accesorios, ropa y mucho más que solamente encontrarás en nuestras instalaciones, porque sabemos que tu estilo de vida también exige moda, por lo tanto en OSVI encontrarás eso que tanto buscas para que tu mascota luzca sana y con tendencia.',
        seoKeywords: 'Boutique de mascotas en Toluca,Ropa para mascotas en Toluca,Accesorios para mascotas en Toluca,Tienda de mascotas en Toluca,Moda para mascotas en Toluca',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'items-center grid lg:grid-cols-2',
        showcaseOrder: '',
        imageClasses: 'rounded-full',
        useImage: {
          show: true,
          imageUrl: 'assets/img/projects/dog_clothes.png',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          lights: false,
          classes: 'bg-gray-500 bg-cover bg-center bg-no-repeat ',
        },
        content: {
          shortLine: 'Accesorios, ropa y mucho más.',
          title: `Boutique`,
          excerpt: `En OSVI así como la salud es integral, también lo es el esparcimiento de tu mascota, por ello contamos con una excelente gama de accesorios, ropa y mucho más que solamente encontrarás en nuestras instalaciones, porque sabemos que tu estilo de vida también exige moda, por lo tanto en OSVI encontrarás eso que tanto buscas para que tu mascota luzca sana y con tendencia.`,
          classesTitle: `text-white text-center text-6xl animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          buttons: [
            {
              id: 0,
              title: 'Agendar una cita',
              ui: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
              redirect: '/contacto',
              icon: 'fa fa-calendar-check-o',
            },
          ],
          switchSkinTextColor: 'text-zinc-200',
        },
        textAlign: 'text-center',
        showSeparator: null,
      },
      overview:
      {
        classesColumn: 'pb-6',
        title: '¡Todas las mascotas felices!',
        classesTitle: `text-4xl`,
        description: `Ofrecemos una amplia gama de accesorios para mascotas de todas las especies, desde perros y gatos hasta erizos, cuyos, tortugas y peces. 
        ¡Encuentra todo lo que necesitas para mimar a tu mascota en un solo lugar!`,
        // imageOther: 'assets/img/default/bg-default.jpg',
        switchBackground: 'bg-white text-zinc-900',
        lottie: null,
        imageOther: 'assets/img/projects/guinea_pig.png',

      },

      slider: [
        {
          imageUrl: 'assets/img/projects/dog_bed.png',
          service: 'Camas',

        },
        {
          imageUrl: 'assets/img/projects/dog_toys.png',
          service: 'Juguetes',

        },
        {
          imageUrl: 'assets/img/projects/cat_wear.png',
          service: 'Ropa para gato',
        },
        {
          imageUrl: 'assets/img/projects/fish_gold.png',
          service: 'Peceras',
        },
        {
          imageUrl: 'assets/img/projects/iguana_terrarium.png',
          service: 'Terrarios',
        },
        {
          imageUrl: 'assets/img/projects/dog_clothing.png',
          service: 'Ropa para perro',
        },
        {
          imageUrl: 'assets/img/projects/cat_backpack.png',
          service: 'Transportadoras',
        },
        {
          imageUrl: 'assets/img/projects/erizo.png',
          service: 'Accesorios para mascotas exóticas',
        },
        {
          imageUrl: 'assets/img/projects/dog_collar.png',
          service: 'Collares, correas y chalecos',
        },
      ],
      callToAction:
      {
        switchBackground: 'bg-white text-zinc-900',
        orderColumn1: 'order-2',
        useBackground: 'assets/img/projects/pug_golden.jpg',
        useBackgroundclasses: 'bg-cover bg-repeat-y bg-right lg:bg-centerflex',
        title: 'Descuentos para la comunidad UAEMEX',
        classesTitle: `text-3xl lg:text-5xl text-white lg:text-zinc-900`,
        description: `<p class="text-white lg:text-zinc-900">La Veterinaria OSVI se enorgullece en anunciar su convenio de descuentos exclusivo para la comunidad UAMEX.
          Ahora, los estudiantes, profesores y personal administrativo de la UAMEX podrán disfrutar de descuentos especiales en productos y servicios de la clínica veterinaria líder en Toluca. 
          Desde servicios médicos hasta alimentación y accesorios para mascotas, OSVI de Toluca es la opción perfecta para el cuidado de su mascota.</p>`,
        button: 'Ver descuentos',
        buttonExternal: false,
        buttonUrl: '/convenio-uaemex',
        buttonIcon: 'fa fa-long-arrow-right '
      }
    }
  }
}


