import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OverviewModel } from '../../../models/overview.model';

@Component({
  selector: 'app-last-call',
  templateUrl: './last-call.component.html',
})
export class LastCallComponent {
  @Input() lastCallData: OverviewModel;

  @Output() eventClick: EventEmitter<void> = new EventEmitter;

  buttonUrlData: string;

  constructor(
    private router: Router,
  ) { }

  buttonUrl = () => {
    this.buttonUrlData = this.lastCallData.buttonUrl!;
    this.router.navigateByUrl(this.buttonUrlData);
  }
}
