import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  data: any;
  safeUrl: any;
  message: string = '';
  disclaimer: string = "";
  validityEnd: string = "";
  validityStart: string = "";
  key: string = '';
  name: string;
  cancelButtonText = "Cancel";


  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<DialogComponent>,
    private _sanitizer: DomSanitizer
  ) {
    if (data) {
      this.message = data.message || this.message;
      this.disclaimer = data.disclaimer || this.disclaimer;
      this.validityStart = data.validityStart || this.validityStart;
      this.validityEnd = data.validityStart || this.validityStart;
      this.key = data.key || this.key;
      this.name = data.name || this.name;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
    this.dialogRef.updateSize('300vw', '300vw')
  }

  ngOnInit(): void {
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/watch?v=' + this.key);
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
