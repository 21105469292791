import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ConvenioService {

  constructor() { }

  /**
   * Set data on services page
   */
  getConvenioData = () => {
    return {
      seo: {
        seoTitle: 'Convenio UAEMEX',
        seoExcerpt: 'En OSVI, consideramos la salud de las mascotas de manera integral. Por eso, ofrecemos un servicio de estética y spa para mascotas en Toluca. ¡Agenda una cita con nosotros y sorpréndete con los resultados!',
        seoKeywords: 'convenia uaemex, descuentos en veterinaria, comunidad universitaria, uaemex',
        seoImage: 'assets/img/og-default.jpg',
      },
      showcase: {
        showcaseColumns: 'items-center grid lg:grid-cols-2',
        showcaseOrder: '',
        imageClasses: 'rounded-full',
        useImage: {
          show: true,
          imageUrl: 'assets/img/projects/woman_dog.png',
        },
        useVideo: {
          show: false,
          videoId: 'UP2XoGfhJ1Y',
        },
        useBackground: {
          urlVideo: null,
          urlImage: null,
          lights: false,
          classes: 'bg-emerald-900 bg-cover bg-center bg-no-repeat ',
        },
        content: {
          shortLine: 'Consentiremos a tu mascota',
          title: `Convenio UAEMEX`,
          excerpt: `En OSVI, consideramos la salud de las mascotas de manera integral. Sabemos que mantener a tu compañero canino o felino aseado y saludable es fundamental para su bienestar. Por eso, ofrecemos un servicio de estética y spa para mascotas en Toluca. 
         ¡Agenda una cita con nosotros y sorpréndete con los resultados!`,
          classesTitle: `text-white text-center text-6xl animate__fadeInDown`,
          classesExcerpt: 'text-center text-white animate__fadeInDown',
          buttons: [
            {
              id: 0,
              title: 'Visitar OSVI',
              ui: 'bg-blue-900 font-semibold text-white shadow-sm hover:bg-blue-700 animate__animated animate__zoomIn',
              redirect: '/contacto',
              icon: 'fa fa-long-arrow-right',
            },
          ],
          switchSkinTextColor: 'text-zinc-200 px-4',
        },
        textAlign: 'text-center',
        showSeparator: null,
      },
      overview: [
        {
          classesColumn: 'py-6',
          title: 'Hasta 10% de descuento en alimento de prescripción médica',
          classesTitle: `text-3xl mb-4`,
          description: `
          <p>Desde 5% hasta 10% de descuento en alimento seco de prescripción médica para perros y gatos.</p>
          <p>Nuestros alimentos de prescripción médica están diseñados específicamente para perros y gatos con necesidades 
          dietéticas especiales, incluyendo problemas de salud como enfermedades del corazón, obesidad y problemas digestivos. 
          Con nuestro descuento, puedes ahorrar dinero en los alimentos que ayudarán a tu mascota a sentirse mejor.</p>
          <p>Términos y condiciones:</p>
          <ul class="hide-list-icon">
          <li class="lg:text-md"><i class="fa fa-paw mr-3" aria-hidden="true"></i>El porcentaje del descuento será otorgado en función de las promociones vigentes del proveedor de alimento seco. Cada 15 días participará una marca y dietas seleccionadas
          y estára sujeta a disponibilidad física en tienda. Se sugiere se comuniquen vía telefónica o whatsapp para verificar existencia.</li>
          <li class="lg:text-md"><i class="fa fa-paw mr-3" aria-hidden="true"></i>No participa alimento enlatado, ni pouches.</li>
          <li class="lg:text-md"><i class="fa fa-paw mr-3" aria-hidden="true"></i>Descuento aplica sólo pago en efectivo.</li>
          </ul>
         `,
          // imageOther: 'assets/img/default/bg-default.jpg',
          switchBackground: 'bg-white text-zinc-900',
          lottie: null,
          imageOther: 'assets/img/projects/dog_food.png',
          imageOtherClasses: 'rounded-full',

        },
        {
          classesColumn: 'py-6',
          title: 'Hasta 10% en accesorios para tus mascotas',
          classesTitle: `text-3xl mb-4`,
          description: `
          <p>Desde 5% hasta 10% de descuento en accesorios para perros, gatos, peces, tortugas, hamsters, cuyos, hurones, erizos, ajolotes, serpientes, iguanaes, camaleones, cerdos vietnamitas, caimanes, loros, canarios y aves en general.</p>
          <p>El porcentaje del descuento será otorgado en función de las promociones vigentes.</p>
          <p>Términos y condiciones: <br>Aplica únicamente pago en <strong>EFECTIVO</strong> y monto mínimo de compra de <strong>$200.00 MXN</strong></p>`,
          // imageOther: 'assets/img/default/bg-default.jpg',
          switchBackground: 'bg-gray-100 text-zinc-900',
          lottie: null,
          imageOther: 'assets/img/projects/dog_toys.png',
          imageOtherClasses: 'rounded-full',

        },
        {
          classesColumn: 'pb-6',
          title: '3x2 Paquete Salud para tus mascotas',
          classesTitle: `text-3xl mb-4`,
          description: `
          <p>En OSVI, estamos emocionados de anunciar nuestro paquete de salud 3x2 para tus mascotas <strong>CANINAS o FELINAS</strong>. 
          Este paquete es la forma perfecta de mantener a tus compañeros peludos saludables y felices, al mismo tiempo que 
          ahorras en tus visitas al veterinario. Además, podrán recibir un juguete para su mascota durante la semana de aniversario 
          de su plantel de la escuela preparatoria, escuela o facultad perteneciente a la UAEMex, presentando una captura
           del post en redes sociales oficiales de la UAEMEX.</p>
          <p>Nuestro Paquete Salud incluye: <strong>Examen físico, desparasitación y vacuna polivalente de acuerdo a su edad.</strong></p>
          <p>Términos y condiciones:</p>
          <ul class="hide-list-icon">
          <li class="lg:text-md"><i class="fa fa-paw mr-3" aria-hidden="true"></i> El propietario pagará los 2 servicios de mayor costo del paquete salud</li>
          <li class="lg:text-md"><i class="fa fa-paw mr-3" aria-hidden="true"></i> La mascota deberá estar 100% sana, no participan mascotas enfermas o con signos de enfermedad</li>
          <li class="lg:text-md"><i class="fa fa-paw mr-3" aria-hidden="true"></i> No participan mascotas bajas de peso o recién adquirirdas (deberán cubrir un mínimo de 15 días con los nuevos dueños y consulta veterinaria previa).</li>
          <li class="lg:text-md"><i class="fa fa-paw mr-3 " aria-hidden="true"></i> El propietario deberá realizar previa cita y mencionar que desea el paquete salud aniversario</li>
          </ul>`,
          // imageOther: 'assets/img/default/bg-default.jpg',
          switchBackground: 'bg-white text-zinc-900',
          lottie: null,
          imageOther: 'assets/img/projects/vet.png',
          imageOtherClasses: 'rounded-full',

        },
      ],

      callToAction:
      {
        switchBackground: 'bg-white text-zinc-900',
        orderColumn1: 'order-1',
        useBackground: 'assets/img/projects/hedgehog_yellow.png',
        useBackgroundclasses: 'bg-cover bg-repeat-y bg-right lg:bg-left flex',
        title: '¿Estás buscando la mejor atención veterinaria para tus mascotas en Toluca?',
        classesTitle: `text-3xl lg:text-5xl text-zinc-900`,
        description: `<p class="text-zinc-900">Nuestro equipo de veterinarios altamente capacitados está dedicado a proporcionar la mejor atención médica para tus animales de compañía, desde perros y gatos hasta aves y reptiles. Contamos con una amplia gama de servicios que incluyen consultas médicas, cirugía, exámenes de diagnóstico, tratamiento de enfermedades, vacunaciones y más.</p>`,
        button: 'Agendar cita',
        buttonExternal: false,
        buttonUrl: '/contacto',
        buttonIcon: 'fa fa-long-arrow-right '
      }
    }
  }
}


